import { forwardRef } from "react"
import { Box, CircularProgress, CircularProgressProps, styled } from "@mui/material"
import { BoxProps } from "@mui/system"

const StyledLoader = styled(CircularProgress)<{ color?: string }>(({ theme, color }) => ({
    color: color || theme.palette.primary.main,
}))

const StyledBox = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
}))

type Props = BoxProps & {
    size?: "medium" | "small" | "extrasmall"
    color?: string
}

export const Loader = forwardRef<HTMLDivElement, Props>(({ size, color, ...rest }, ref) => {
    const circularProgressProps: CircularProgressProps = {}
    switch (size) {
        case "extrasmall":
            circularProgressProps.size = "16px"
            break
        case "small":
            circularProgressProps.size = "20px"
            break
        case "medium":
        default:
            break
    }

    return (
        <StyledBox ref={ref} {...rest}>
            <StyledLoader {...circularProgressProps} sx={{ color }} />
        </StyledBox>
    )
})
