import { Box } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Cost, CostsType } from "@tm/models"
import { Typography } from "../../../generics/typographie"
import { PriceGrid } from "./PriceGrid"

type Props = {
    costs: Cost[]
}

export function Costs({ costs }: Props) {
    const { translateText, currency } = useLocalization()

    function getCostDescription(costType: CostsType) {
        switch (costType) {
            case CostsType.Unknown:
                return translateText(1391)
            case CostsType.DefaultDispatch:
                return translateText(1292)
            case CostsType.Express:
                return translateText(13726)
            case CostsType.Enviromental:
                return translateText(13727)
            case CostsType.Packaging:
                return translateText(13728)
            case CostsType.Pledge:
                return translateText(13724)
            case CostsType.Procurement:
                return translateText(13725)
            case CostsType.OldPartDiscount:
                return translateText(1614)
            default:
                return translateText(1391)
        }
    }

    function renderCost(cost: Cost) {
        return (
            <>
                <Typography lineHeight={1.2} variant="body2" textAlign="right">
                    {getCostDescription(cost.type)}:
                </Typography>
                <Typography lineHeight={1.2} variant="body2" textAlign="right">
                    {currency(cost.value, cost.currencySymbol)}
                </Typography>
                {/* Fill empty space to fit with prices */}
                <Box />
            </>
        )
    }

    return <PriceGrid>{costs.map((cost) => renderCost(cost))}</PriceGrid>
}
