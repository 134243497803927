import { memo } from "react"
import { Box, Fade, Grid, Stack, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { AdditionalInformations } from "../../AdditionalInformations"
import { RightActions } from "../../Actions/RightActions"
import { ArticleNumbers } from "../../ArticleNumbers"
import { Attributes } from "../../Attributes"
import { Descriptions } from "../../Descriptions"
import { DefaultArticleErpContainer } from "../../ErpContainer"
import { CenterActions } from "../../Actions/CenterActions"
import { Image } from "../../Image"
import { Notifications } from "../../Notifications"
import { References } from "../../References"
import { Supplier } from "../../Supplier"
import { Transactions } from "../../Transactions"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { BonusSystem } from "../../BonusSystem"
import { Icon } from "../../../../generics/Icons"
import { ExpandIconButton, GridItem } from "./ArticleItemComponents"
import { DmsArticleInformation } from "../../Dms/DmsArticleInformation"
import AlreadyOrderedArticle from "../../Vouchers/AlreadyOrderedArticle"
import { Watchlist } from "../../Watchlist"
import { Tooltip } from "../../../../generics/tooltip"
import { useArticleItemState } from "../../ContextProviderStates"
import { SeasonIconWithTyreEfficiency } from "../Tyres/components/SeasonIconWithTyreEfficiency"

export const StyledGridItem = styled(GridItem)({
    width: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
}) as typeof GridItem

export const DefaultExpandedArticleContentComponent = memo(() => {
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const dmsInformation = useDefaultArticleItemState((x) => x.dmsArticleInfo)
    const articleOrdered = useDefaultArticleItemState((x) => x.alreadyOrdered)
    const { hasWatchList, highlightWatchlistButton, hideCostEstimationButton, hideAddToBasketButton, showReplaceButton } = useDefaultArticleItemState(
        (x) => x.options
    )
    const { translateText } = useLocalization()
    const isTyres = useArticleItemState((x) => x.type === "Tyres")
    const isPartToReplace = hideAddToBasketButton && hideCostEstimationButton && !showReplaceButton

    const handleExpand = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, true)
        toggleExpand()
    }

    return (
        <Grid container direction="row" wrap="nowrap" alignItems="start">
            {/* Expand Icon */}
            <StyledGridItem>
                {/* Article Image, Supplier */}
                <Grid item>
                    <Stack alignItems="center" gap="2px">
                        <Image />
                        <Supplier />
                        <BonusSystem />
                    </Stack>
                </Grid>

                {/* Expand Icon */}
                <Grid item alignSelf="start">
                    <Tooltip title={translateText(13862)}>
                        <ExpandIconButton onClick={handleExpand} hoverEffect disableRipple>
                            <Icon name="minus" />
                        </ExpandIconButton>
                    </Tooltip>
                </Grid>
            </StyledGridItem>

            {/* Article Infos */}
            <GridItem item xs p={1} overflow="hidden">
                <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack>
                            <ArticleNumbers />
                            {!isTyres && <Descriptions />}
                            {isTyres && <SeasonIconWithTyreEfficiency />}
                        </Stack>
                        <CenterActions />
                    </Stack>

                    <Box>
                        <Attributes />

                        <Fade in unmountOnExit mountOnEnter appear={false}>
                            <Box>
                                <References />
                                <AdditionalInformations />
                                <Notifications />
                            </Box>
                        </Fade>
                    </Box>

                    {articleOrdered && <AlreadyOrderedArticle />}
                    <DmsArticleInformation dmsArticleInfo={dmsInformation} />
                </Stack>
            </GridItem>

            {/* Actions, ERP Infos */}
            <Grid item justifyContent="end" paddingX={1.5} paddingY={1} minWidth="258px" alignSelf={isPartToReplace ? "stretch" : "auto"}>
                <Stack spacing={2} sx={{ flexGrow: "1", height: "100%", justifyContent: "space-between" }}>
                    <RightActions />
                    {hasWatchList && highlightWatchlistButton ? (
                        <Stack direction="row" justifyContent="end">
                            <Watchlist />
                        </Stack>
                    ) : (
                        <>
                            <Transactions />
                            <DefaultArticleErpContainer />
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    )
})
