import { Backdrop } from "@mui/material"
import { OAuthTokenIds } from "@tm/models"
import { renderRoute } from "@tm/utils"
import { MouseEvent, PropsWithChildren, useState } from "react"
import { Button, ButtonProps } from "../../generics/button"

type Props = ButtonProps & {
    tokenId: OAuthTokenIds
    onAuthorizationFinished?(status: "success" | "error" | "abort"): void
}

export function OAuthButton({ tokenId, children, ...rest }: PropsWithChildren<Props>) {
    const [processStarted, setProcessStarted] = useState(false)

    function handleFinishProcess(status: OAuthMessageEventData["status"]) {
        setProcessStarted(false)
        window.removeEventListener("message", handleMessage)

        rest.onAuthorizationFinished?.(status)
    }

    function handleMessage(event: MessageEvent) {
        if (event.origin !== window.origin || !isOAuthMessageEventData(event.data)) {
            return
        }

        handleFinishProcess(event.data.status)
    }

    function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
        setProcessStarted(true)

        window.addEventListener("message", handleMessage)
        window.open(renderRoute("/oauth/:tokenId", { tokenId }), "oauth", "popup,width=700,height=750,left=1500,top=100")

        rest.onClick?.(event)
    }

    function handleBackdropClick() {
        handleFinishProcess("abort")
    }

    return (
        <>
            <Button {...rest} onClick={handleButtonClick}>
                {children}
            </Button>
            <Backdrop sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })} open={processStarted} onClick={handleBackdropClick} />
        </>
    )
}

type OAuthMessageEventData = {
    type: "oauth-status"
    status: "success" | "error" | "abort"
}

function isOAuthMessageEventData(data: any): data is OAuthMessageEventData {
    return (
        !!data &&
        typeof data == "object" &&
        "type" in data &&
        data.type === "oauth-status" &&
        "status" in data &&
        (data.status === "success" || data.status === "error" || data.status === "abort")
    )
}
